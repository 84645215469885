/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Craft = {
        // All pages
        'common': {
            init: function () {

                /**
                 * Ajax form submission
                 */
                $("form").on({
                    submit: function () {
                        var $self = $(this);
                        $(".alert.alert-success").remove();
                        $("button[type=submit]", $self).prop("disabled", true);

                        $.ajax({
                            url: $("input[name=action]", $(this)).val(),
                            type: "post",
                            dataType: "json",
                            data: $(this).serialize(),
                            success: function (response) {
                                $("ul.errors.help-block", $self).remove();
                                $(".has-error", $self).removeClass("has-error");

                                if (response.success && response.finished) {
                                    $self[0].reset();
                                    $self.slideUp();

                                    var $successMessage = $("<div>", {"class": "alert alert-success"})
                                        .append("<p>Form submitted successfully</p>", {"class": "lead"});

                                    $self.before($successMessage);

                                } else if (response.errors) {
                                    for (var key in response.errors) {
                                        if (!response.errors.hasOwnProperty(key)) continue;
                                        var messages = response.errors[key];
                                        var $errors = $("<ul>", {"class": "errors help-block"});
                                        for (var i = 0; i < messages.length; i++) {
                                            var $li = $("<li>");
                                            $li.html(messages[i]);
                                            $errors.append($li);
                                        }

                                        var $input = $("*[name=" + key + "], *[name='" + key + "[]']");

                                        const $formGroup = $input.parents(".form-group");
                                        $formGroup.addClass("has-error");

                                        $formGroup.append($errors);
                                    }
                                }

                                $("button[type=submit]", $self).prop("disabled", false);
                            }
                        });

                        return false;
                    }
                });

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Craft;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
